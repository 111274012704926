/* .App {
  padding: 1vmin;
} */

.test {
  background-color: #282c34;
  padding-left: 2vmin;
  padding-right: 2vmin;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media (max-width: 575.98px) {
  .logos-vr {
    visibility: hidden;
  }
}